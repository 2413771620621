<template>
  <div class="card card-body shadow-sm infomations-list">
    <kyy-app-modal-standard-order @handleUpdateSuccess="handleUpdateSuccess" :endPoint="endPointEdit" ref="modal_list_order"></kyy-app-modal-standard-order>
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
    <app-basic-table
      ref="basic_table"
      :table-name="$t('restaurant.list_title')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.KYY_STANDARD_ORDER_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-4 pl-0">
            <div class="form-group start_date">
              <label class="col-xl-9 col-lg-12 col-sm-12">{{ filters.start_date.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12">
                  <app-date-range-picker
                    :rules="`from_date_time_list_page:${filters.close_date.value}`"
                    :name="filters.start_date.name"
                    v-model="filters.start_date.value"
                    format="YYYY-MM-DD H:mm"
                    :timePickerMinutes="true"
                    @input="filters.start_date.value = $event"/>
                </div>
                <span class="align-self-center align-items-center mr-lg-2 mr-xl-5" style="font-size: 30px">~</span>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label class="col-xl-4 col-lg-4 col-sm-8" style="opacity: 0">1</label>
              <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                <app-date-range-picker
                  :name="filters.close_date.name"
                  v-model="filters.close_date.value"
                  format="YYYY-MM-DD H:mm"
                  :timePickerMinutes="true"
                  @input="filters.close_date.value = $event"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- Search by restaurant name -->
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group start_date">
              <label class="col-xl-12 col-lg-12 col-sm-12">{{ filters.kyy_restaurant_id.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                  <app-select :name="filters.kyy_restaurant_id.name"
                    input-style="normal"
                    v-model="filters.kyy_restaurant_id.value"
                    :options-data="meta.restaurants"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Search by payment method -->
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group start_date">
              <label class="col-xl-12 col-lg-12 col-sm-12">{{ filters.payment.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                  <app-select :name="filters.payment.name"
                      input-style="normal"
                      v-model="filters.payment.value"
                      :options-data="STANDARD_ORDER_LIST_CONSTANT.STANDARD_PAYMENT_METHOD"
                    />
                </div>
              </div>
            </div>
          </div>

          <!-- Search by status -->
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group start_date">
              <label class="col-xl-12 col-lg-12 col-sm-12">{{ filters.status.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                  <app-select :name="filters.status.name"
                      input-style="normal"
                      v-model="filters.status.value"
                      :options-data="STANDARD_ORDER_LIST_CONSTANT.STANDARD_ORDER_STATUS"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnDetailClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common_vn.detail") }}
          </button>
        </td>
      </template>

      <template v-slot:body-cell-total_cost="props">
        <td class="app-align-middle text-right">
          {{ convertCurrency(props.row.total_cost) }}
        </td>
      </template>

      <template v-slot:body-cell-restaurant="props">
        <td class="app-align-middle text-left">
          {{  props.row.restaurant.name }}
        </td>
      </template>

      <template v-slot:body-cell-payment="props">
        <td class="app-align-middle text-center">
          {{ getLabelFromOptionArray(props.row.payment, STANDARD_ORDER_LIST_CONSTANT.STANDARD_PAYMENT_METHOD) }}
        </td>
      </template>

      <template v-slot:body-cell-status="props">
        <td class="app-align-middle">
          {{ getLabelFromOptionArray(props.row.status, STANDARD_ORDER_LIST_CONSTANT.STANDARD_ORDER_STATUS) }}
        </td>
      </template>
    </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {STANDARD_ORDER_LIST_CONSTANT} from "../../../constants/kyy_standard_order_list";
  import {convertCurrency, getLabelFromOptionArray} from "@utils";
  import KyyAppModalStandardOrder from "@components/_common/popup/KyyAppModalStandardOrder";

  export default {
    name: "StandardOrderListPage",
    components: {
      "app-basic-table": AppBasicTable,
      "kyy-app-modal-standard-order": KyyAppModalStandardOrder,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          status: {
            name: "status",
            condition: "equal",
            label: this.$t("standard_order.status")
          },
          kyy_restaurant_id: {
            name: "kyy_restaurant_id",
            condition: "equal",
            label: this.$t("standard_order.restaurant_name")
          },
          payment: {
            name: "payment",
            condition: "equal",
            label: this.$t("standard_order.payment")
          },
          start_date: {
            name: "start_time_between",
            condition: "between",
            label: this.$t("standard_order.order_time")
          },
          close_date: {
            name: "close_time_between",
            condition: "between",
            label: this.$t("reservation.status")
          },
        },
        columns: [
          {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-right'},
          {name: "restaurant", label: this.$t("standard_order.restaurant_name"), sortable: true, textAlign: 'text-left'},
          {name: "customer_name", label: this.$t("standard_order.customer_name"), textAlign: 'text-left'},
          {name: "customer_phone", label: this.$t("standard_order.customer_phone"), textAlign: 'text-left'},
          {name: "status", label: this.$t("standard_order.status"), sortable: true, textAlign: 'text-center'},
          {name: "order_time", label: this.$t("standard_order.order_time"), sortable: true, textAlign: 'text-center'},
          {name: "total_cost", label: this.$t("standard_order.price"), sortable: true, textAlign: 'text-right'},
          {name: "payment", label: this.$t("standard_order.payment"), textAlign: 'text-center'},
          {name: "edit", label: this.$t("common_vn.action"), textAlign: 'text-center'},
        ],
        meta: [],
        endPointEdit: ''
      };
    },
    watch : {
      "filters.from_date_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
      "filters.to_date_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
    },
    methods: {
      async updateStatus(e, id) {
        await this.$request.get(this.ENDPOINT.KYY_STANDARD_ORDER_EDIT(id)).then(res => {
          if (res.hasErrors()) {
            return ;
          }
          this.entry = res.data.data
        })

        if (this.entry.status != e) {
          let res = null;
          let msg = "";
          res = await this.$request.patch(this.ENDPOINT.KYY_STANDARD_ORDER_EDIT(id), {
            status : e
          });
          msg = this.$t("common.msg update ok")
          if (res.hasErrors()) {
            this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.__noticeSuccess(msg);
          }
        }
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {
            // 'filters.order_code.equal' : 'all',
            // 'filters.order_date.equal' : 'all'
          }
        })
      },
      getOrderSize(sizeID) {
        let type = STANDARD_ORDER_LIST_CONSTANT.ITEM_SIZE.find(({id}) => id === sizeID);
        if (type == undefined) {
          return '';
        }
        return type.name;
      },
      onSearch() {
        // this.getTourSelectName();
      },
      // handle open edit order
      handleBtnDetailClick(entry) {
        this.endPointEdit = this.ENDPOINT.KYY_STANDARD_ORDER_EDIT(entry.id)
        this.$refs.modal_list_order.show(this.endPointEdit);
      },

      handleUpdateSuccess() {
        this.$refs.basic_table.getEntries();
      }
    },

    mounted: async function () {
      // call api get list restaurant
      let response = await this.$request.get(this.ENDPOINT.KYY_RESTAURANT_LIST_SELECT);
      if (response.hasErrors()) {
        if (response.status === 'error'){
          this.__noticeError(this.$t(response.data.error_msg));
        }
      } else {
        let restaurants = response.data.data.map(item => {
          return {
            id: item.id,
            name: item.name
          }
        });
        this.meta = {
          restaurants: restaurants,
        }
      }
    },
  }
</script>
